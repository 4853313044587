import React from "react"
import Container from "components/Container"
import cx from "classnames"
import Img from "gatsby-image/withIEPolyfill"
import TitleBlock from "./TitleBlock"
import styles from "./WeWorkWithSection.module.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        }
    ]
}

export const WeWorkWithSection = (props) => (

    <Container className={cx(styles.root, {[styles.whiteBG]: props.isWhiteBG })}>
        <div>
            <TitleBlock isWhite>We Work With</TitleBlock>
            <div className={styles.sliderContainer}>
                <Slider {...settings} className={styles.kdSlider}>
                    {(props.partners || []).filter(Boolean).map(({ image, link, imageAltText }, i) => (
                        <div>
                            {link ? (
                                <a className={styles.img}
                                    key={i}
                                    href={link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {image && image.extension === "svg" ? (
                                        <img className={styles.sliderImage} alt={imageAltText ? imageAltText : props.name} title={props.name} src={image.publicURL} width="160" height="40" loading="lazy"/>
                                    ) : image ? (
                                        <Img
                                            className={styles.sliderImage}
                                            objectFit="contain"
                                            alt={imageAltText ? imageAltText : props.title}
                                            title={props.title}
                                            fluid={image.childImageSharp.fluid}
                                        />
                                    ) : null}
                                </a>
                            ) : (
                                <div className={styles.img} key={i}>
                                    {image && image.extension === "svg" ? (
                                        <img className={styles.sliderImage} alt={imageAltText ? imageAltText : props.name} title={props.name} src={image.publicURL} width="160" height="40" loading="lazy" />
                                    ) : image ? (
                                        <Img
                                            className={styles.sliderImage}
                                            objectFit="contain"
                                            alt={imageAltText ? imageAltText : props.title}
                                            title={props.title}
                                            fluid={image.childImageSharp.fluid}
                                        />
                                    ) : null}
                                </div>
                            )}
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    </Container>
)


export default WeWorkWithSection
