import React from "react"
import marked from "marked"
import { Link } from "gatsby"
import cx from "classnames"
import Img from "gatsby-image/withIEPolyfill"
import Container from "components/Container"
import TitleBlock from "./TitleBlock"
import styles from "./IconsGridWithBackground.module.css"

export const IconsGridWithBackground = (props) => (
  <Container className={cx({ [styles.root]: styles.root, }, props.className, {
      [styles.gray]: props.bgColor === 'isGray',
      [styles.gradient]: props.bgColor === 'isGradient',
      [styles.noPaddingTop]: props.paddingTop === 'noPadding',
      [styles.noPaddingBottom]: props.paddingBottom === 'noPadding',
      [styles.fourCol]: props.layout === 'fourCol',
      [styles.fourColLeft]: props.layout === 'fourColLeft',
      [styles.twoCol]: props.layout === 'twoCol',
      [styles.twoColLeft]: props.layout === 'twoColLeft',
      [styles.twoColLeftPpc]: props.layout === 'twoColLeftPpc',
      [styles.twoColOracle]: props.layout === 'twoColOracle',
      [styles.alignleft]: props.layout === 'alignLeft',
      [styles.textLeft]: props.layout === 'textLeft'
  })}>
      <Container className={styles.rootInner}>
        <TitleBlock isLarger><span dangerouslySetInnerHTML={{ __html: props.title || ""}} /></TitleBlock>
        {props.description ? <div className={styles.desc} dangerouslySetInnerHTML={{ __html: marked(props.description || "") }} /> : null}
        {props.image ? (
        <div className={styles.mainimage}>
            {props.image.extension === "svg" ? (
            <img alt={props.image.name} title={props.image.name} src={props.image.publicURL} loading="lazy"/>
            ) : props.image.childImageSharp ? (
            <Img
                loading="lazy"
                objectFit="fit"
                alt={props.image.name}
                title={props.image.name}
                fluid={props.image.childImageSharp.fluid}
            />
            ) : null}
        </div>
        ) : null}
        {props.secondImage ? (
        <div className={styles.mainimage}>
            {props.secondImage.extension === "svg" ? (
            <img alt={props.secondImageAltText ? props.secondImageAltText : props.secondImage.name} title={props.secondImage.name} src={props.secondImage.publicURL} width="1100" height="500" loading="lazy"/>
            ) : props.secondImage.childImageSharp ? (
            <Img
                loading="lazy"
                objectFit="fit"
                alt={props.secondImageAltText ? props.secondImageAltText : props.secondImage.name}
                title={props.secondImage.name}
                fluid={props.secondImage.childImageSharp.fluid}
            />
            ) : null}
        </div>
        ) : null}
        {props.fullWidthImageGrid ? (
            <div className={styles.imagelist}>
            {(props.fullWidthImageGrid || []).filter(Boolean).map(({ image, link }, i) => (
              <div className={styles.imageitem}>
                {link ? (
                  <a className={styles.img}
                    key={i}
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                  {image && image.extension === "svg" ? (
                    <img className={styles.gridimage} alt={props.name} title={props.name} src={image.publicURL} width="398" height="150" loading="lazy"/>
                  ) : image ? (
                    <Img 
                    loading="lazy"
                    className={styles.gridimage}
                    objectFit="contain"
                    alt={props.title}
                    title={props.title}
                    fluid={image.childImageSharp.fluid}
                    />
                  ) : null}
                  </a>
                ) : (
                  <div className={styles.img} key={i}>
                  {image && image.extension === "svg" ? (
                    <img className={styles.gridimage} alt={props.name} title={props.name} src={image.publicURL} width="398" height="150" loading="lazy"/>
                  ) : image ? (
                    <Img 
                    loading="lazy"
                    className={styles.gridimage}
                    objectFit="contain"
                    alt={props.title}
                    title={props.title}
                    fluid={image.childImageSharp.fluid}
                    />
                  ) : null}
                  </div>
                )}
              </div>
            ))}
            </div>
        ) : null}
        {props.iconsGridList ? (
        <div className={styles.icons}>
            {(props.iconsGridList || []).filter(Boolean).map((item, i) => (
                <div className={styles.item} key={i}>
                {item.icon && item.icon.extension === "svg" ? (
                    <div className={cx({ [styles.icon]: styles.icon, }, props.className, {
                        [styles.bigIcon]: props.iconSize === 'isBig', [styles.biggerIcon]: props.iconSize === 'isBigger'
                      })}>
                        {item.link ? (
                            <a href={item.link} rel="noopener noreferrer" target="_blank">
                                <img alt={item.imageAltText ? item.imageAltText : item.title} title={item.title} src={item.icon.publicURL} loading="lazy" width="80" height="80"/>
                            </a>
                        ) : <img alt={item.imageAltText ? item.imageAltText : item.title} title={item.title} src={item.icon.publicURL} loading="lazy" width="80" height="80"/>}
                    </div>
                ) : item.icon && item.icon.childImageSharp ? (
                    <div className={cx({ [styles.icon]: styles.icon, }, props.className, {
                        [styles.bigIcon]: props.iconSize === 'isBig', [styles.biggerIcon]: props.iconSize === 'isBigger'
                      })}>
                        {item.link ? (
                                    <a href={item.link} rel="noopener noreferrer" target="_blank">
                                        <Img
                                            loading="lazy"
                                            objectFit="contain"
                                            alt={item.imageAltText ? item.imageAltText : item.title}
                                            title={item.title}
                                            fluid={item.icon.childImageSharp.fluid}
                                        />
                                </a>
                            ) :
                            <Img
                                loading="lazy"
                                objectFit="contain"
                                alt={item.imageAltText ? item.imageAltText : item.title}
                                title={item.title}
                                fluid={item.icon.childImageSharp.fluid}
                            />
                        }
                    </div>
                ) : null}
                {item.title ? <h3>{item.title}</h3> : null}
                {item.subtitle && item.title ? <h4 dangerouslySetInnerHTML={{ __html: item.subtitle || "" }} /> : item.subtitle ? <h3 className={styles.itemTitleSmall} dangerouslySetInnerHTML={{ __html: item.subtitle || "" }} /> : null}
                {item.description ? <div dangerouslySetInnerHTML={{ __html: marked(item.description || "") }} /> : null}
                </div>
            ))}
            </div>
        ) : null}
        {props.buttonText ? (
            <Link className={styles.button} to={`${props.buttonUrl}`}>{props.buttonText}</Link>
        ) : null}
      </Container>
  </Container>
)

export default IconsGridWithBackground
