import React from "react"
import { Link } from "gatsby"
import cx from "classnames"
import marked from "marked"
import Container from "components/Container"
import Img from "gatsby-image/withIEPolyfill"
import TitleBlock from "./TitleBlock"
import styles from "./TextWithImageAndCTA.module.css"



export const TextWithImageAndCTA = (props) => (
  <div className={cx({ [styles.root]: styles.root, }, props.className, {
    [styles.gray]: props.bgColor === 'isGray',
    [styles.gradient]: props.bgColor === 'isGradient',
    [styles.white]: props.bgColor === 'isWhite',
    [styles.houseOfBrick]: props.bgColor === 'isHouseOfBrick',
    [styles.noPaddingTop]: props.paddingTop === 'noPadding',
  })}>
    <Container>
        <div className={cx({ [styles.flex]: styles.flex, }, props.className, {
          [styles.sameWidth]: props.imageWidth === 'sameWidth',
          [styles.flexImageRight]: props.imageWidth === 'defaultWidth'
        })}>
            <div className={cx({ [styles.img]: styles.img, }, props.className, {
              [styles.imageRight]: props.imagePosition === 'right'
            })}>
              {props.externalLink ? (
                <Link to={`${props.externalLink}`} target="_blank">
                  {props.image.extension === "svg" ? (
                    <img alt={props.imageAltText ? props.imageAltText : props.image.name} title={props.image.name} src={props.image.publicURL} loading="lazy" width="500" height="500"/>
                  ) : props.image.childImageSharp ? (
                    <Img
                      loading="lazy"
                      className={styles.gridimage}
                      objectFit="contain"
                      alt={props.imageAltText ? props.imageAltText : props.image.name}
                      title={props.image.name}
                      fluid={props.image.childImageSharp.fluid}
                    />
                  ) : null}
                </Link>
              ) : (
                <div>
                  {props.image.extension === "svg" ? (
                    <img alt={props.imageAltText ? props.imageAltText : props.image.name} title={props.image.name} src={props.image.publicURL} loading="lazy" width="500" height="500"/>
                  ) : props.image.childImageSharp ? (
                    <Img
                      loading="lazy"
                      className={styles.gridimage}
                      objectFit="contain"
                      alt={props.imageAltText ? props.imageAltText : props.image.name}
                      title={props.image.name}
                      fluid={props.image.childImageSharp.fluid}
                    />
                  ) : null}
                </div>
              )}
            </div>
            <div className={styles.text}>
              {props.secondImage ? (
                <div className={styles.secondImg}>
                  {props.secondImage.extension === "svg" ? (
                    <img alt={props.name} title={props.name} src={props.secondImage.publicURL} loading="lazy" width="200" height="200"/>
                  ) : props.secondImage.childImageSharp ? (
                    <Img
                      loading="lazy"
                      className={styles.gridimage}
                      objectFit="contain"
                      alt={props.name}
                      title={props.name}
                      fluid={props.secondImage.childImageSharp.fluid}
                    />
                  ) : null}
                </div>
              ) : null}
              <TitleBlock isLarger><span>{props.subTitle}</span>{props.title}</TitleBlock>
              <div className={styles.desc} dangerouslySetInnerHTML={{ __html: marked(props.description || "") }} />
              {props.buttonText ? (
                <Link className={styles.button} to={`${props.buttonUrl}`}>{props.buttonText}</Link>
              ) : null}
            </div>
        </div>
    </Container>
  </div>
)

export default TextWithImageAndCTA
