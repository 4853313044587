import React from "react"
import Container from "components/Container"
import { Link } from "gatsby"
import cx from "classnames"
import Img from "gatsby-image/withIEPolyfill"
import TitleBlock from "./TitleBlock"
// import BlogPostsCards from "components/BlogPostsCards"
import styles from "./BlogPostsSection.module.css"



export const BlogPostsSection = (props) => (
  <div className={cx({ [styles.root]: styles.root, }, props.className, {
    [styles.gray]: props.bgColor === 'isGray',
  })}>
    <Container>
        <TitleBlock isLarger>{props.title ? props.title : props.data.mainBlog.title ? props.data.mainBlog.title : null}</TitleBlock>
        <div className={styles.cards}>
          {(props.edges || []).filter(Boolean).map((item, i) => (
              <div className={styles.card}>
                  {item.node.frontmatter.image ? (
                    <div className={styles.postimage}>
                      <Img
                        loading="lazy"
                        objectFit="cover"
                        alt={item.node.frontmatter.title}
                        title={item.node.frontmatter.title}
                        fluid={item.node.frontmatter.image.childImageSharp.fluid}
                      />
                    </div>
                  ) : null}
                  <div className={styles.tag}>
                      {item.node.frontmatter.tags ? item.node.frontmatter.tags[0]: null}
                  </div>
                  <h3><Link className={styles.cardtitle} to={`/post/${item.node.frontmatter.url}`}>{item.node.frontmatter.title}</Link></h3>
                  <p className={styles.date}>{item.node.frontmatter.date}</p>
              </div>
          ))}
        </div>
    </Container>
  </div>
)

export default BlogPostsSection
